import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import { cilCloudDownload } from '@coreui/icons';
import { useNavigate } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { CSVLink } from 'react-csv';
import CIcon from '@coreui/icons-react';
import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Painel() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [alunos, setAlunos] = useState(false)
    const [dados, setDados] = useState(false)
    const [alunosBkp, setAlunosBkp] = useState(false)

    const pullAlunos = async () => {
        await api.get("/alunos/obterAlunos").then(rs => {
            setAlunos(rs.data)
            setAlunosBkp(rs.data)
        }).catch(console.log("Erro ao puxar alunos!"));
    }

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('auth');

        navigate("/")
    }

    const deletarAluno = async (aluno) => {
        await api.post(`/alunos/deletarAluno/${aluno._id}`, {}).then(rs => {
            alert(`aluno ${aluno.nome} deletado com sucesso!`)
            window.location.reload();
        }).catch(console.log("Erro ao puxar relatorio!"));
    }

    const pullRelatorio = async () => {
        await api.post("/alunos/obterRelatorio", {alunos: alunos}).then(rs => {
            setDados(rs.data)
        }).catch(console.log("Erro ao puxar relatorio!"));
    }

    const voucher = () => {
        navigate('/cadastrar-voucher')
    }

    const listarVouchers = () => {
        navigate('/listar-vouchers')
    }

    const handleFiltro = (e, b) => {
        e.preventDefault();
        let filtered_alunos = []
        switch (e.target.lastChild.data) {
            case 'Anual - 2 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "anual-2")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;
            case 'Anual - 3 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "anual-3")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;
            case 'Mensal - 2 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "mensal-2")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;
            case 'Mensal - 3 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "mensal-3")
                setAlunos(filtered_alunos) 
                pullRelatorio();
                break;
            case 'Trimestral - 2 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "trimestral-2")
                setAlunos(filtered_alunos)  
                pullRelatorio();
                break;    
            case 'Trimestral - 3 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "trimestral-3")
                setAlunos(filtered_alunos)  
                pullRelatorio();
                break;
            case 'Semestral - 2 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "semestral-2")
                setAlunos(filtered_alunos) 
                pullRelatorio(); 
                break;          
            case 'Semestral - 3 aulas':
                filtered_alunos = alunosBkp.filter(aluno => aluno.plano == "semestral-3")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;    
            case 'Titular':
                filtered_alunos = alunosBkp.filter(aluno => aluno.categoria == "titular")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;    
            case 'Dependente':
                filtered_alunos = alunosBkp.filter(aluno => aluno.categoria == "dependente")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;    
            case 'Pix':
                filtered_alunos = alunosBkp.filter(aluno => aluno.pagamento == "pix")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;    
            case 'Boleto':
                filtered_alunos = alunosBkp.filter(aluno => aluno.pagamento == "boleto")
                setAlunos(filtered_alunos)
                break;
            case 'Cartão':
                filtered_alunos = alunosBkp.filter(aluno => aluno.pagamento == "cartao")
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;  
            case 'Muay Thai':
                filtered_alunos = alunosBkp.filter(aluno => aluno.modalidade.includes("Muay Thai"))
                setAlunos(filtered_alunos)
                pullRelatorio();
                break; 
            case 'Jiu-Jitsu':
                filtered_alunos = alunosBkp.filter(aluno => aluno.modalidade.includes("Jiu-Jitsu"))
                setAlunos(filtered_alunos)
                pullRelatorio();
                break;
            case 'Judô':
                filtered_alunos = alunosBkp.filter(aluno => aluno.modalidade.includes("Judô"))
                setAlunos(filtered_alunos)
                pullRelatorio();
                break    
            case 'Taekwondo':
                filtered_alunos = alunosBkp.filter(aluno => aluno.modalidade.includes("Taekwondo"))
                setAlunos(filtered_alunos)
                pullRelatorio();
                break  
            case 'Karatê':
                filtered_alunos = alunosBkp.filter(aluno => aluno.modalidade.includes("Karatê"))
                setAlunos(filtered_alunos)
                pullRelatorio();
                break      
            default: 
                setAlunos(alunosBkp) 
                pullRelatorio();                 
        }
    }
    useEffect(() => {
        pullAlunos();
        pullRelatorio();
    }, []);

    useEffect(() => {
        pullRelatorio();
    }, [alunos]);


    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Button href="/" color="primary" variant="outlined" className={classes.link}>
                            Home
                    </Button>
                    <Button onClick={() => logOut()} color="primary" variant="outlined" className={classes.link}>
                        Log Out
                    </Button>
                    <Button onClick={() => voucher()} color="primary" variant="outlined" className={classes.link}>
                        Cadastrar Voucher
                    </Button>
                    <Button onClick={() => listarVouchers()} color="primary" variant="outlined" className={classes.link}>
                        Listar Vouchers
                    </Button>
                </Toolbar>
            </AppBar>
            <Container  component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
                    Gadelha Team
                </Typography>
              
                <Container className={classes.dropdownsContainer}>
                <MDBDropdown>
                    <MDBDropdownToggle tag='a' className='btn btn-secondary'>
                        Filtrar por pagamento
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Pix</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Boleto</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Cartão</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
                <MDBDropdown className={classes.dropdownItem}>
                    <MDBDropdownToggle tag='a' className='btn btn-secondary'>
                        Filtrar por Modalidade
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Jiu-Jitsu</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Muay Thai</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Judô</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Taekweondo</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Karatê</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
                <MDBDropdown  className={classes.dropdownItem}>
                    <MDBDropdownToggle tag='a' className='btn btn-secondary'>
                        Filtrar por tipo de conta
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)} link>Titular</MDBDropdownItem>
                        <MDBDropdownItem  onClick={e => handleFiltro(e, e.target.value)} link>Dependente</MDBDropdownItem>
                    </MDBDropdownMenu>
                    </MDBDropdown>
                    <MDBDropdown  className={classes.dropdownItem}>
                    <MDBDropdownToggle tag='a' className='btn btn-secondary'>
                        Filtrar por plano
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem  onClick={e => handleFiltro(e, e.target.value)} link>Anual - 2 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Anual - 3 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Mensal - 2 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Mensal - 3 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Trimestral - 2 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Trimestral - 3 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Semestral - 2 aulas</MDBDropdownItem>
                        <MDBDropdownItem onClick={e => handleFiltro(e, e.target.value)}link>Semestral - 3 aulas</MDBDropdownItem>
                    </MDBDropdownMenu>
                    </MDBDropdown>
                </Container>
            </Container>
            <Container maxWidth="md" component="main">
                <MDBTable>
                    <MDBTableHead className={classes.tabela}>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>E-mail</th>
                            <th scope='col'>CPF</th>
                            <th scope='col'>Plano</th>
                            <th scope='col'>Modalidades</th>
                            <th scope='col'>Pagamento</th>
                            <th scope='col'>Tipo de Cadastro</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                            <th scope='col'>
                                <CSVLink data={dados ? dados : ""} filename={'dados.csv'}>
                                    <Button>
                                        <CIcon className={classes.icone} icon={cilCloudDownload} title="Download file" />
                                    </Button>
                                </CSVLink>
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {alunos ? alunos.map((aluno, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <th scope='row'>{index}</th>
                                        <td>{aluno.email}</td>
                                        <td>{aluno.cpf}</td>
                                        <td>{aluno.plano}</td>
                                        <td> {aluno ? aluno.modalidade.map(modalidade => {
                                                return (
                                                    <li>{modalidade}</li>
                                                )
                                            }) : ''}</td>
                                        <td>{aluno.pagamento ? aluno.pagamento : 'Dinheiro'}</td>
                                        <td>{aluno.categoria}</td>
                                        <td><button onClick={() =>  navigate(`/editar-aluno`, { state: { aluno: aluno } }) } class="btn btn-primary">Editar</button></td>
                                        <td><button onClick={() => deletarAluno(aluno)} class="btn btn-danger">Excluir</button></td>
                                        <td></td>
                                    </tr>
                                </>
                            )
                        }) : ''}
                    </MDBTableBody>
                </MDBTable>
            </Container>
            <Container maxWidth="md" component="footer" className={classes.footer}>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}
