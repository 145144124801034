import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Cadastro from './pages/cadastro';
import Login from './pages/login';
import Painel from './pages/painel';
import Busca from './pages/busca';
import CadastrarVoucher from './pages/cadastrar-voucher';
import ListarVouchers from './pages/listar-vouchers';
import EditarVoucher from './pages/editar-voucher';
import EditarAluno from './pages/editar-aluno';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="cadastro" element={<Cadastro />} />
            <Route path="login" element={<Login />} />
            <Route path="busca" element={<Busca />} />
            <Route path="dashboard" element={
              localStorage.getItem("token") !== null ? (
                <Painel />
              ) : (
                <Navigate to="/login" />
              )
            } />
            <Route path="cadastrar-voucher" element={
              localStorage.getItem("token") !== null ? (
                <CadastrarVoucher />
              ) : (
                <Navigate to="/login" />
              )
            } />
             <Route path="listar-vouchers" element={
              localStorage.getItem("token") !== null ? (
                <ListarVouchers />
              ) : (
                <Navigate to="/login" />
              )
            } />
              <Route path="editar-voucher" element={
              localStorage.getItem("token") !== null ? (
                <EditarVoucher />
              ) : (
                <Navigate to="/login" />
              )
            } />
             <Route path="editar-aluno" element={
              localStorage.getItem("token") !== null ? (
                <EditarAluno />
              ) : (
                <Navigate to="/login" />
              )
            } />
          </Routes>
        </Router>

    </div >
  );
}

export default App;
