import React, { useReducer, useState } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CForm } from '@coreui/react';
import { CFormInput } from '@coreui/react';
import { CCol } from '@coreui/react';
import { CButton } from '@coreui/react';
import { useLocation } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { useNavigate } from "react-router-dom";
import { CFormSelect } from '@coreui/react';

import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

export default function   CadastrarVoucher({ route, navigation }) {
  const { state } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [formData, setFormData] = useReducer(formReducer, {});
  const [isEmailValid, setValidEmail] = useState(false)
  const [submit, setSubmit] = useState(false)

  const handleChange = (event, value) => {
    setFormData({
      name: event.name || event.label || event.target.name,
      value: value,
    });
  };

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('auth');

    navigate("/")
}

  const validateEmail = (value) => {
    if (value.length > 0) setValidEmail(true)
    if (value.length === 0) setValidEmail(false)

    return value
  }

  const cadastrarVoucher = async (e) => {
    e.preventDefault();
    setSubmit(true)
    if (isEmailValid) {
      await api.post("/vouchers/cadastrarVoucher", {
        codigo: formData.codigo,
        desconto: formData.desconto,
        tipo_de_desconto: formData.tipo_de_desconto,

    }).then(rs => {
        window.location.replace("/dashboard")
    }).catch(err => alert("Erro ao cadastrar voucher!"))}
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
        <Button  href="dashboard" className={classes.link}  variant="outlined"  color="primary" >
            HOME
          </Button>
          <Button onClick={() => logOut()} color="primary" variant="outlined" className={classes.link}>
            LOG OUT
          </Button>
          <Button  href="dashboard" className={classes.link}  variant="outlined"  color="primary" >
            DASHBOARD                               
          </Button>
          <Button  href="listar-vouchers" className={classes.link}  variant="outlined"  color="primary" >
            LISTAR VOUCHERS                               
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
          Gadelha Team
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <div className={classes.heroContent}>
          <div className={classes.centralDiv}>
            <CForm className="row g-3">
              <CCol xs={12}>
                <CFormInput value={formData.codigo} onChange={e => handleChange(e, validateEmail(e.target.value))} feedback="Digite um Código válido." name="codigo" id="inputCodigo" label="Código" placeholder="Digite o código" required />
              </CCol>
              <CCol xs={12}>
                <CFormInput value={formData.desconto} type="text" onChange={e => handleChange(e, e.target.value)} feedback="Digite um desconto válido." name="desconto" id="inputDesconto" label="Desconto" placeholder="Digite o desconto"  required />
              </CCol>
              <CCol xs={12}>
              <CFormSelect type="text" onChange={e => handleChange(e, e.target.value)} name="tipo_de_desconto" id="inputDescontoPlano" label="Tipo de Desconto" required>
                  <option value="R$" >Selecione uma opção</option>
                  <option value="%" >%</option>
                  <option value="R$" >R$</option>
                </CFormSelect>
              </CCol>
              <CCol xs={12}>
                <CButton onClick={e => cadastrarVoucher(e)} className={classes.cadastrarButton} color="primary" type="submit">Cadastrar Voucher</CButton>
              </CCol>
            </CForm>
          </div>
        </div>
      </Container>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
}
