import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useNavigate, useLocation } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { CButton } from '@coreui/react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Busca() {
    const navigate = useNavigate();
    let { state } = useLocation();

    const classes = useStyles();
    const [aluno, setAluno] = useState(state.aluno)
    const [submit, setSubmit] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [currentMonth, setCurrentMonth] = useState(false)
    const [copiaEColaDialog, setCopiaEColaDialog] = useState(false)
    const [currentCode, setCurrentCode] = useState("")

    const Transition = React.forwardRef(function Transition(
        props,
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const Transition2 = React.forwardRef(function Transition(
        props,
        ref,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClickOpen = (month) => {
        setCurrentMonth(month)
        setOpen(true);
    };

    const handleQRCode = (e, month) => {
        setOpen(false);
        callSearch(e, month, 'qrcode')
    };

    const handleCopiaECola = (e, month) => {
        callSearch(e, month, 'copiaecola')
    };

    const voltar = () => {
        navigate("/")
    }

    const closeDialog = () => {
        setCopiaEColaDialog(false)
    }

    useEffect(() => {
    }, [])


    const setPaid = async (e, month) => {
        await api.post(`/payment/alterarStatusPago`, {
            "cpf": aluno.aluno.cpf,
            "month": month,
        }).then(async (rs) => {
            await api.get(`/alunos/obterAluno/${aluno.aluno.cpf}`).then(rs => {
                setAluno(rs.data)
                state = { aluno: rs.data }
            }).catch(error => console.log(error));
        }).catch(error => console.log(error));
    }
    const callSearch = async (e, month, metodo) => {
        e.preventDefault();
        setSubmit(true)

        switch (aluno.aluno.pagamento) {
            case 'pix':
                await api.post(`/payment/pix`, {
                    "name": aluno.aluno.nome,
                    "email": aluno.aluno.email,
                    "plano": aluno.aluno.plano,
                    "modalidades": aluno.aluno.modalidade,
                    "voucher": aluno.aluno.voucher,
                    "cpf": aluno.aluno.cpf,
                    "due": month.month,
                    "ddd": "84",
                    "number": aluno.aluno.celular,
                }).then(rs => {
                    if (metodo === "qrcode") window.open(rs.data.qrcodeurl, '_blank')
                    if (metodo === "copiaecola") {
                        setCurrentCode(rs.data.qrcode)
                        console.log(rs.data.qrcode)
                        console.log("CURRENT CODE", currentCode)
                    }
                }).catch(error => console.log(error));
                break;
            case "boleto":
                await api.post(`/payment/boleto`, {
                    "name": aluno.aluno.nome,
                    "email": aluno.aluno.email,
                    "voucher": aluno.aluno.voucher,
                    "plano": aluno.aluno.plano,
                    "modalidades": aluno.aluno.modalidade,
                    "cpf": aluno.aluno.cpf,
                    "due": month.month,
                }).then(rs => {
                    window.open(rs.data.url, '_blank')
                }).catch(error => console.log(error));
                break;
            default:
                alert("construction")
                break;
        }
    }
    return (
        <React.Fragment>
            <CssBaseline />

            {open ? (
                <React.Fragment>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted

                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"QR Code ou Pix Cópia e Cola?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                { currentCode.length > 0 ? `${currentCode}` : 'Escolha uma das opções para gerar seu código cópia e cola ou qrcode'}
                                
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => { 
                                if (currentCode.length == 0) handleCopiaECola(e, currentMonth)
                                if (currentCode.length > 0) setOpen(false)
                                }
                            }>{currentCode.length == 0 ? 'Cópia e cola' : 'Fechar'}</Button>
                            {currentCode.length == 0 ? (<Button onClick={(e) => handleQRCode(e, currentMonth)}>QRCode</Button>) : '' }
                        </DialogActions>
                    </Dialog>
                </React.Fragment>

            ) : (
                <React.Fragment>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition2}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Pix Cópia e Cola"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                bom dia manicaca
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Fechar</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}

            {copiaEColaDialog ? (
                <React.Fragment>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition2}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Pix Cópia e Cola"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                bom dia manicaca
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Fechar</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            ) : ''}
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>

                <Toolbar className={classes.toolbar}>
                    <Button onClick={() => voltar()} color="primary" variant="outlined" className={classes.link}>
                        Voltar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
                    Gadelha Team
                </Typography>
            </Container>
            <Container maxWidth="md" component="main">
                <MDBTable>
                    <MDBTableHead className={classes.tabela}>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>CPF</th>
                            <th scope='col'>Plano</th>
                            <th scope='col'>Modalidades</th>

                            <th scope='col'>Data</th>
                            <th scope='col'>Pagamento</th>
                            <th scope='col'>Status</th>
                            {aluno.aluno.pagamento !== 'cartao' ? (
                                <th></th>
                            ) : ''}
                            {
                                localStorage.getItem("token") !== null ? (
                                    <th></th>
                                ) : ('')
                            }
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {aluno.aluno ? aluno.aluno.faturas?.map((month, index) => {
                            return (
                                <>

                                    <tr key={index}>
                                        <th scope='row'>{index}</th>
                                        <td>{aluno.aluno.cpf}</td>
                                        <td>{aluno.aluno.plano}</td>
                                        <td> {aluno.aluno ? aluno.aluno.modalidade.map(modalidade => {
                                            return (
                                                <li>{modalidade}</li>
                                            )
                                        }) : ''}</td>
                                        <td>{month.month}</td>
                                        <td>{aluno.aluno.pagamento ? aluno.aluno.pagamento : 'Dinheiro'}</td>
                                        <td>{aluno.aluno.voucher?.codigo === "GD-BOLSA-24" ? "PAGO" : month.status}</td>
                                            {aluno.aluno.voucher?.codigo !== "GD-BOLSA-24" && aluno.aluno.pagamento !== "cartao" ? (
                                                <td>
                                                <CButton onClick={e => callSearch(e, month)} className={classes.cadastrarButton} color="primary" type="submit">Pagar</CButton>
                                                </td> 
                                            ) : '' }

                                        {
                                            localStorage.getItem("token") !== null && aluno.aluno.voucher?.codigo !== "GD-BOLSA-24" ? (
                                                <td>
                                                    <CButton onClick={e => setPaid(e, month.month)} className={classes.cadastrarButton} color="success" type="submit">V</CButton>
                                                </td>
                                            ) : ''
                                        }
                                    </tr>
                                </>
                            )
                        }) : state.aluno.aluno.faturas?.map((month, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <th scope='row'>{index}</th>
                                        <td>{aluno.aluno.cpf}</td>
                                        <td>{aluno.aluno.plano}</td>
                                        <td>{month.month}</td>
                                        <td>{aluno.aluno.pagamento ? aluno.aluno.pagamento : 'Dinheiro'}</td>
                                        <td>{aluno.aluno.voucher?.codigo === "GD-BOLSA-24" ? "PAGO" : month.status}</td>
                                        <td>
                                            <CButton onClick={e => handleClickOpen(e, month)} className={classes.cadastrarButton} color="primary" type="submit">Pagar</CButton>

                                        </td>
                                        {
                                            localStorage.getItem("token") !== null ? (
                                                <td>
                                                    <CButton onClick={e => setPaid(e, month.month)} className={classes.cadastrarButton} color="success" type="submit">V</CButton>
                                                </td>
                                            ) : ''
                                        }
                                    </tr>
                                </>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
            </Container>
            <Container maxWidth="md" component="footer" className={classes.footer}>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}
