import React, { useReducer, useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { CForm } from '@coreui/react';
import { CFormInput } from '@coreui/react';
import { CCol } from '@coreui/react';
import { CFormSelect } from '@coreui/react';
import { CButton } from '@coreui/react';
import { useNavigate, useLocation } from "react-router-dom";
import { useStyles } from './styles';
import Box from '@material-ui/core/Box';
import { formatCPF, normalizePhoneNumber } from '../../helpers/format';
import { Copyright } from "../copyright";
import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FlipRounded } from "@material-ui/icons";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

export default function EditarAluno() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [formData, setFormData] = useReducer(formReducer, {});
  const [isNameValid, setValidName] = useState(false)
  const [isCPFValid, setValidCPF] = useState(false)
  const [isEmailValid, setValidEmail] = useState(false)
  const [isPhoneValid, setValidPhone] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [showCartao, setShowCartao] = useState(false)
  const [showTitular, setShowTitular] = useState(false)
  const [modalidades, setModalidades] = useState([])

  const handleChange = (event, value) => {
    if (value === "cartao") setShowCartao(true)
    if (event.target.name === "pagamento" && value !== "cartao") setShowCartao(false)
    if (event.target.name === "tipo_da_conta" && value === "dependente") setShowTitular(true)
    if (event.target.name === "modalidade") modalidades.push(value)
    setFormData({
      name: event.name || event.label || event.target.name,
      value: value,
    });
  };

  const validateNome = (value) => {
    if (value.length > 0) setValidName(true)
    if (value.length === 0) setValidName(false)

    return value
  }

  const validateCPF = (value) => {
    if (value.length > 0) setValidCPF(true)
    if (value.length === 0) setValidCPF(false)

    return value
  }

  const validateEmail = (value) => {
    if (value.length > 0) setValidEmail(true)
    if (value.length === 0) setValidEmail(false)

    return value
  }

  const validatePhone = (value) => {
    if (value.length > 0) setValidPhone(true)
    if (value.length === 0) setValidPhone(false)

    return value
  }
  useEffect(() => {
    setModalidades([])
    setShowTitular(false)
  }, [])
  const callEdit = async (e) => {
    e.preventDefault();
    setSubmit(true)
        await api.post(`/alunos/editarAluno/${state.aluno._id}`, {
              nome: formData.nome ? formData.nome : state.aluno.nome, 
              email: formData.email ? formData.email : state.aluno.email, 
              plano: formData.plano ? formData.plano : state.aluno.plano, 
              cpf: formData.cpf ? formData.cpf : state.aluno.cpf, 
              celular: formData.celular ? formData.celular : state.aluno.celular, 
              pagamento: formData.pagamento ? formData.pagamento : state.aluno.pagamento, 
              cpfTitular: formData.cpfTitular ? formData.cpfTitular : state.aluno.cpfTitular, 
              voucher: formData.voucher ? formData.voucher : state.aluno.voucher, 
              categoria: formData.categoria ? formData.categoria : state.aluno.categoria, 
              modalidade: modalidades ? modalidades : modalidades,

          }).then(rs => {
            alert(`Usuário ${state.aluno.nome} editado!`)
            navigate("/")
          }).catch(err => alert("Erro ao editar!"));
    
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>

        <Toolbar className={classes.toolbar}>
          <Button href="/login" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
          Gadelha Team
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <div className={classes.heroContent}>
          <div className={classes.centralDiv}>
            <CForm className="row g-3">
              <CCol md={12}>
                <CFormInput value={formData.nome ? formData.nome : state.aluno.nome} feedback="O Campo nome deve ser preenchido." onChange={e => handleChange(e, validateNome(e.target.value))} name="nome" id="inputNome" label="Nome Completo:"  required />
              </CCol>
              <CCol xs={12}>
                <CFormInput value={formData.email ? formData.email : state.aluno.email} onChange={e => handleChange(e, validateEmail(e.target.value))} feedback="Digite um E-mail válido." name="email" id="inputEmail" label="Email:" placeholder="Digite seu E-mail" required />
              </CCol>
              <CCol md={6}>
                <CFormInput onChange={e => handleChange(e, formatCPF(validateCPF(e.target.value)))} feedback="Digite um CPF válido." name="cpf" value={formData.cpf ? formatCPF(formData.cpf) : state.aluno.cpf}  type="text" id="inputCpf" label="CPF:" placeholder="___.___.___-__"  required />
              </CCol>
              <CCol xs={2}>
                <CFormInput value={formData.ddd ? formData.ddd : state.aluno.ddd} onChange={e => handleChange(e, e.target.value)} feedback="Digite um DDD válido." name="ddd" id="inputDDD" label="DDD:" placeholder="84" required />
              </CCol>
              <CCol xs={4}>
                <CFormInput value={formData.celular ? formData.celular : state.aluno.celular} onChange={e => handleChange(e, validatePhone(e.target.value))} feedback="Digite um celular válido." name="celular" id="inputCelular" label="Celular:" placeholder="9999-9999" required />
              </CCol>
              <CCol md={6}>
                <CFormSelect selected={state.aluno.plano} type="text" onChange={e => handleChange(e, e.target.value)} name="plano" id="inputPlano" label="Plano" required>
                  <option value="anual-2" selected={state?.plano === "anual-2" ? true : false}>Anual - 2 aulas</option>
                  <option value="anual-3" selected={state?.plano === "anual-3" ? true : false}>Anual - 3 aulas</option>
                  <option value="mensal-2" selected={state?.plano === "mensal-2" ? true : false}>Mensal - 2 aulas</option>
                  <option value="mensal-3" selected={state?.plano === "mensal-3" ? true : false}>Mensal - 3 aulas</option>
                  <option value="trimestral-2" selected={state?.plano === "trimestral-2" ? true : false}>Trimestral - 2 aulas</option>
                  <option value="trimestral-3" selected={state?.plano === "trimestral-3" ? true : false}>Trimestral - 3 aulas</option>
                  <option value="semestral-2" selected={state?.plano === "semestral-2" ? true : false}>Semestral - 2 aulas</option>
                  <option value="semestral-3" selected={state?.plano === "semestral-3" ? true : false}>Semestral - 3 aulas</option>
                </CFormSelect>
              </CCol>
              <CCol md={6}>
                <CFormSelect type="text" onChange={e => handleChange(e, e.target.value)} name="pagamento" id="inputPagamento" label="Metodo de pagamento" required>
                  <option>Escolha um metodo de pagamento...</option>
                  <option value="pix">Pix</option>
                  <option value="boleto" >Boleto</option>
                  <option value="cartao" >Cartão</option>
                </CFormSelect>
              </CCol>
              <CCol md={12}>
              <CFormInput value={formData.voucher ? formData.voucher : state.aluno.voucher} onChange={e => handleChange(e, e.target.value)} feedback="Digite um voucher válido." name="voucher" id="inputVoucher" label="Voucher:" placeholder="XYZQER"  required />
              </CCol>
              <CCol md={12}>
                <CFormSelect type="text" onChange={e => handleChange(e, e.target.value)} name="modalidade" id="inputModalidade" label="Modalidade:" required>
                  <option>Escolha uma modalidade...</option>
                  <option value="Muay Thai">Muay Thai</option>
                  <option value="Jiu-Jitsu" >Jiu-Jitsu</option>
                  <option value="Judô" >Judô</option>
                  <option value="Taekwondo" >Taekwondo</option>
                  <option value="Karate" >Karatê</option>
                </CFormSelect>
              </CCol>
              <CCol md={12}>
                <div className={classes.modadalidadesContainer}>
                  <ul>
                    {

                      modalidades ? modalidades.map(modalidade => {
                        return (
                          <li>{modalidade}</li>
                        )
                      }) : ''
                    }
                  </ul>
                </div>
              </CCol>
              <CCol md={12}>
                <CFormSelect type="text" onChange={e => handleChange(e, e.target.value)} name="tipo_da_conta" id="inputTipoDaConta" label="Tipo da Conta:" required>
                  <option>Tipo de Cadastro...</option>
                  <option value="titular">Titular</option>
                  <option value="dependente" >Dependente</option>
                </CFormSelect>
              </CCol>

              {showTitular ? (
                <>
                  <CCol md={12}>
                    <CFormInput onChange={e => handleChange(e, formatCPF(validateCPF(e.target.value)))} feedback="Digite um CPF válido." name="cpfTitular" value={formData.cpfTitular ? formatCPF(formData.cpfTitular) : state.aluno.cpfTitular} type="text" id="cpfTitular" label="CPF do titular:" placeholder="___.___.___-__"  required />
                  </CCol>
                </>
              ) : ''}
              {showCartao ? (
                <>
                  <CCol xs={12}>
                    <CFormInput value={formData.holderName} onChange={e => handleChange(e, e.target.value)} feedback="Digite um nome válido." name="holderName" id="inputHolderName" label="Nome no cartão" placeholder="Digite o nome no cartão" invalid={!isEmailValid && submit} required />
                  </CCol>

                  <CCol md={12}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um cartão válido." name="cardNumber" value={formData.cardNumber} type="text" id="inputCardNumber" label="Número do cartão" placeholder=""  required />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput value={formData.billingDay} feedback="O Campo dia de cobrança deve ser preenchido." onChange={e => handleChange(e, validateNome(e.target.value))} name="billingDay" id="inputBillingday" label="Dia de cobrança:"  required />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um mes válido." name="expMonth" value={formData.expMonth} type="text" id="inputExpMonth" label="Mês do vencimento" placeholder=""  required />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um ano válido." name="expYear" value={formData.expYear} type="text" id="inputExpYear" label="Ano do vencimento" placeholder=""  required />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um cvv válido." name="cvv" value={formData.cvv} type="text" id="inputCvv" label="CVV:" placeholder=""  required />
                  </CCol>
                  <CCol md={9}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um endereço válido." name="address1" value={formData.address1} type="text" id="inputAddress1" label="Rua:" placeholder=""  required />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um número válido." name="address2" value={formData.address2} type="text" id="inputAddress2" label="Bairro:" placeholder=""  required />
                  </CCol>

                  <CCol md={4}>
                    <CFormInput feedback="O Campo CEP deve ser preenchido." onChange={e => handleChange(e, e.target.value)} name="zipCode" id="inputCEP" label="CEP:"  required />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite uma cidade válida." name="city" value={formData.city} type="text" id="inputCity" label="Cidade:" placeholder=""  required />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput onChange={e => handleChange(e, e.target.value)} feedback="Digite um estado válido." name="state" value={formData.state} type="text" id="inputState" label="Estado:" placeholder=""  required />
                  </CCol>

                </>
              ) : ''}
              <CCol xs={12}>
                <CButton onClick={e => callEdit(e)} className={classes.editarButton} color="primary" type="submit">Editar</CButton>
              </CCol>
            </CForm>
          </div>
        </div>
      </Container>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
}
