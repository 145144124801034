import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import { cilCloudDownload } from '@coreui/icons';
import { useNavigate } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';
import { CSVLink } from 'react-csv';

import CIcon from '@coreui/icons-react';
import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ListarVouchers() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [vouchers, setVouchers] = useState(false)
    const [dados, setDados] = useState(false)

    const pullVouchers = async () => {
        await api.get("/vouchers/obterVouchers").then(rs => {
            setVouchers(rs.data)
        }).catch(console.log("Erro ao puxar alunos!"));
    }

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('auth');

        navigate("/")
    }


    const voucher = () => {
        navigate('/cadastrar-voucher')
    }

    const editarVoucher = (voucher) => {
        navigate(`/editar-voucher`, { state: { data: voucher} })
    }

    const deletarVoucher = async (voucher) => {
        await api.post(`/vouchers/deletarVoucher/${voucher._id}`).then(rs => {
            pullVouchers()
        }).catch(console.log("Erro ao deletar voucher"))
    }

    useEffect(() => {
        pullVouchers();
    }, []);

    useEffect(() => {

    }, [vouchers]);


    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Button  href="dashboard" className={classes.link}  variant="outlined"  color="primary" >
                        HOME
                    </Button>
                    <Button onClick={() => logOut()} color="primary" variant="outlined" className={classes.link}>
                        Log Out
                    </Button>
                    <Button onClick={() => voucher()} color="primary" variant="outlined" className={classes.link}>
                        Cadastrar Voucher
                    </Button>
                    <Button  href="dashboard" className={classes.link}  variant="outlined"  color="primary" >
                        DASHBOARD
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
                    Gadelha Team
                </Typography>
            </Container>
            <Container maxWidth="md" component="main">
                <MDBTable>
                    <MDBTableHead className={classes.tabela}>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Código</th>
                            <th scope='col'>Desconto</th>
                            <th scope='col'>Tipo de Desconto</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {vouchers ? vouchers.map((voucher, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <th scope='row'>{index}</th>
                                        <td>{voucher.codigo}</td>
                                        <td>{voucher.desconto}</td>
                                        <td>{voucher.tipo_de_desconto}</td>
                                        <td> <Button  color="primary" variant="outlined" onClick={() => editarVoucher(voucher)} className={classes.link}>Editar</Button></td>
                                        <td> <Button  color="warn" variant="outlined" onClick={() => deletarVoucher(voucher)}  className={classes.link}>Deletar</Button></td>
                                    </tr>
                                </>
                            )
                        }) : ''}
                    </MDBTableBody>
                </MDBTable>
            </Container>
            <Container maxWidth="md" component="footer" className={classes.footer}>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}
