import React, { useReducer, useState } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CForm } from '@coreui/react';
import { CFormInput } from '@coreui/react';
import { CCol } from '@coreui/react';
import { CButton } from '@coreui/react';
import { useLocation } from "react-router-dom";
import { Copyright } from "../copyright";
import { useStyles } from './styles';

import api from "../../hooks/api";
import 'bootstrap/dist/css/bootstrap.min.css';

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

export default function Login({ route, navigation }) {
  const { state } = useLocation();
  const classes = useStyles();

  const [formData, setFormData] = useReducer(formReducer, {});
  const [isEmailValid, setValidEmail] = useState(false)
  const [submit, setSubmit] = useState(false)

  const handleChange = (event, value) => {
    setFormData({
      name: event.name || event.label || event.target.name,
      value: value,
    });
  };

  const validateEmail = (value) => {
    if (value.length > 0) setValidEmail(true)
    if (value.length === 0) setValidEmail(false)

    return value
  }

  const callLogin = async (e) => {
    e.preventDefault();
    setSubmit(true)
    if (isEmailValid) {
      await api.post("/auth/login", {
        email: formData.email,
        password: formData.password,
    }).then(rs => {
        const auth = rs.data.auth;
        const user = rs.data.user;
        const token = rs.data.token;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user || {}));
        localStorage.setItem('auth', auth);
        api.defaults.headers.Authorization = `Bearer ${token}`;
        window.location.replace("/dashboard")
    }).catch(err => alert("Erro ao logar!"))}
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" fontFamily="Courier New" className={classes.titleGD} gutterBottom>
          Gadelha Team
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <div className={classes.heroContent}>
          <div className={classes.centralDiv}>
            <CForm className="row g-3">
              <CCol xs={12}>
                <CFormInput value={formData.email} onChange={e => handleChange(e, validateEmail(e.target.value))} feedback="Digite um E-mail válido." name="email" id="inputEmail" label="Email" placeholder="Digite seu E-mail" invalid={!isEmailValid && submit} required />
              </CCol>
              <CCol xs={12}>
                <CFormInput value={formData.password} type="password" onChange={e => handleChange(e, validateEmail(e.target.value))} feedback="Digite uma senha válida." name="password" id="inputEmail" label="Senha" placeholder="Digite seu E-mail" invalid={!isEmailValid && submit} required />
              </CCol>
              <CCol xs={12}>
                <CButton onClick={e => callLogin(e)} className={classes.cadastrarButton} color="primary" type="submit">Login</CButton>
              </CCol>
            </CForm>
          </div>
        </div>
      </Container>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
}
